




















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { IProject, PROJECT } from '@/interface/project';
import { GROUP } from '@/interface/group';
import RegisterByProject from '@/components/project/registerByProject.vue';
import RegisterByGroup from '@/components/group/registerByGroup.vue';
import dayjs from 'dayjs';
import { PROJECT_ERROR } from '@/enum/message/project';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';

@Component({
  components: {
    TopShortcutLeft,
    RegisterByProject,
    RegisterByGroup,
  },
})
export default class List extends Vue {
  dayjs = dayjs;
  pending: boolean = false;
  search: string = '';
  status: PROJECT.PROJECT_STATUS = PROJECT.PROJECT_STATUS.ALL;
  projectArray: Array<IProject> = [];
  sort: number = -1;
  page: number = 1;
  limitPage: number = 10;
  totalRow: number = 0;
  PROJECT = PROJECT;
  GROUP = GROUP;
  selectId: string | null = null;
  projectStepIndex: number = 0;
  projectStatusTabIndex: number = 0;
  projectInfo: IProject | null = null;
  groupId: string | null = null;

  $refs!: Vue['$refs'] & {
    registerByProject: RegisterByProject;
    registerByGroup: RegisterByGroup;
  };

  async created() {
    await this.projectList();
  }

  showProjectModal(_id: string, index: number) {
    this.selectId = _id;
    this.projectStepIndex = index;
    this.$bvModal.show('createProjectModal');
    this.$nextTick(() => {
      this.$refs.registerByProject.load();
    });
  }

  async closeModal() {
    await this.projectList();

    /*this.$nextTick(() => {
      this.$bvModal.hide("createProjectModal");
    });*/
  }

  openGroupModal(project: IProject, groupId: string | null) {
    const { complete } = project;
    if (!complete)
      return this.$toast.open({
        message: PROJECT_ERROR.NOT_COMPLETE,
        type: 'error',
      });

    this.projectInfo = project;
    this.groupId = groupId;
    this.$bvModal.show('groupModal');
    this.$nextTick(() => {
      this.$refs.registerByGroup.load();
    });
  }

  async projectList(): Promise<void> {
    this.pending = true;
    try {
      const sendData = {
        status: this.status,
        page: this.page,
        limitPage: this.limitPage,
        search: this.search,
        sort: this.sort,
      };

      const { data } = await this.axios.get(`/project`, {
        params: sendData,
      });
      if (!data.result) return;
      const { projects, total } = data;
      this.totalRow = total;
      this.projectArray = projects.map((p: any) => {
        return {
          groupShow: false,
          ...p,
        };
      });
    } catch (e) {
      console.log(e);
    }
    this.pending = false;
  }

  getGroupStatus(status: GROUP.GROUP_STATUS): string {
    const find = GROUP.GROUP_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }

  getProjectStatus(status: PROJECT.PROJECT_STATUS): string {
    const find = PROJECT.PROJECT_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }

  groupStatusColor(status: GROUP.GROUP_STATUS): string {
    if (status == GROUP.GROUP_STATUS.EXPECT) return 'dark';
    else if (status == GROUP.GROUP_STATUS.END) return 'danger';
    else if (status == GROUP.GROUP_STATUS.PROGRESS) return 'info';
    else if (status == GROUP.GROUP_STATUS.COMPLETED) return 'success';
    else return `secondary`;
  }

  projectStatusColor(status: string): string {
    if (status == '대기') return 'warning';
    else if (status == '예정') return 'dark';
    else if (status == '진행') return 'info';
    else if (status == '완료') return 'success';
    else return `secondary`;
  }

  projectStatusChange(tab: number): void {
    if (tab == 0) this.status = PROJECT.PROJECT_STATUS.ALL;
    else if (tab == 1) this.status = PROJECT.PROJECT_STATUS.EXPECT;
    else if (tab == 2) this.status = PROJECT.PROJECT_STATUS.PROGRESS;
    else if (tab == 3) this.status = PROJECT.PROJECT_STATUS.COMPLETED;
    this.projectList();
  }

  sortChange() {
    this.sort = this.sort * -1;
    this.page = 1;
    this.projectList();
  }
}
